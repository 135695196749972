.mainBackground {
  height: 103vh;
  width: 103vw;
  padding: 0;
  background-size: cover;
  background-position: 60% 25%;
  position: absolute;
  filter:grayscale(50%);
}
.title {
  max-width: 100vw;
  max-height: 50vh;
  padding: 0;
  position: absolute;
  top: -5px;
  right: -5px;
}
.join {
  max-width: 100vw;
  max-height: 50vh;
  padding: 0;
  position: absolute;
  bottom: -5px;
  left: -5px;
}
.subtitle {
  font-size: 1.1em;
  line-height: 2em;
  font-weight: 500;
}

body {
  overflow: hidden;
}
