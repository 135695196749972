@import "~antd/dist/antd.css";
@import "~antd-button-color/dist/css/style.css";
body {
  margin: 0;
  font-family: "PingFangSC-Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Impact;
  src: url('assets/impact.ttf');
}