/*

Exit Transition
showFront = true -> false
front -> back

Enter Transition
showFront = false -> true
back -> front

*/

.flip-exit {
  transform: rotateY(0deg);
}

.flip-exit-active {
  transform: rotateY(180deg);
  transition: transform 400ms;
  transition-timing-function: cubic-bezier(.48,.02,.53,1.66);/*cubic-bezier(.57,.21,.69,1.25);*/
}

.flip-exit-done {
  transform: rotateY(180deg);
}

.flip-enter {
  transform: rotateY(180deg);
}

.flip-enter-active {
  transform: rotateY(0deg);
  transition: transform 400ms;
  transition-timing-function: cubic-bezier(.48,.02,.53,1.66);
}

.flip-enter-done {
  transform: rotateY(0deg);
}

.numbers{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position:50% 50%;
}
.card {
  /* Sizing */
  width: 18vw;
  height: 24vw;
  
  transform-style: preserve-3d;
  transition: all 400ms;
  transition-timing-function: cubic-bezier(.48,.02,.53,1.66);

  user-select: none;
  /* cursor: pointer; */
}


.cardEntry{
  transform: translateY(100vh);
}

.cardExit{
  transform: translateY(-100vh);
}
.cardStay{
  transform: translateY(0);
}


.card-front,
.card-back {
  /* Sizing */
  height: 100%;
  width: 100%;

  backface-visibility: hidden;

  /* Alignment */
  position: absolute;

  /* Content Alignment */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 400ms;
  transition-timing-function: cubic-bezier(.48,.02,.53,1.66);
}

.card-front {
  transform: rotateY(0deg);

}

.card-back {
  transform: rotateY(180deg);
}



.mainBackground {
  padding: 0;
  background-position: 50% 30%;
  background-size: cover;
  margin: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -100;
  position: absolute;
}



.frontSakura {
  margin: 0;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100vh;
  width: fit-content;
  min-width: 100vw;
  z-index: 99;
  position: absolute;
  opacity: 1;
  background-position: 50% 30%;
  pointer-events: none;
}

.title {
  max-width: 95vw;
  min-width: 50vw;
  max-height: 80vh;
  padding: 0;
  position: absolute;
  bottom: 5vh;
  left: 50vw;
  transform: translateX(-50%);
}

.join {
  max-width: 100vw;
  max-height: 50vh;
  padding: 0;
  position: absolute;
  bottom: -5px;
  left: -5px;
}

.subtitle {
  font-size: 1.1em;
  line-height: 2em;
  font-weight: 500;
}

body {
  overflow: hidden;
}

.fadein {
  animation: fadeIn 4s;
  -webkit-animation: fadeIn 4s;
  -moz-animation: fadeIn 4s;
  -ms-animation: fadeIn 4s;
  -o-animation: fadeIn 4s;
}

.rotatein {
  animation: rotateIn 4s;
  -webkit-animation: rotateIn 4s;
  -moz-animation: rotateIn 4s;
  -o-animation: rotateIn 4s;
  -ms-animation: rotateIn 4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
    opacity: 0;
    transform: scale(0) rotate(0deg) translate3d(-50%, -50%, 0);
    -o-transform: scale(0) rotate(0deg) translate3d(-50%, -50%, 0);
    -webkit-transform: scale(0) rotate(0deg) translate3d(-50%, -50%, 0);
    -ms-transform: scale(0) rotate(0deg) translate3d(-50%, -50%, 0);
    -moz-transform: scale(0) rotate(0deg) translate3d(-50%, -50%, 0);
  }

  20% {
    opacity: .5;
    transform: scale(0) rotate(0deg) translate3d(-50%, -50%, 0);
    -o-transform: scale(0) rotate(0deg) translate3d(-50%, -50%, 0);
    -webkit-transform: scale(0) rotate(0deg) translate3d(-50%, -50%, 0);
    -ms-transform: scale(0) rotate(0deg) translate3d(-50%, -50%, 0);
    -moz-transform: scale(0) rotate(0deg) translate3d(-50%, -50%, 0);
  }

  50% {
    opacity: 1;
    transform: scale(1) rotate(1080deg) translate3d(-50%, -50%, 0);
    -o-transform: scale(1) rotate(1080deg) translate3d(-50%, -50%, 0);
    -webkit-transform: scale(1) rotate(1080deg) translate3d(-50%, -50%, 0);
    -ms-transform: scale(1) rotate(1080deg) translate3d(-50%, -50%, 0);
    -moz-transform: scale(1) rotate(1080deg) translate3d(-50%, -50%, 0);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(1080deg) translate3d(-50%, -50%, 0);
    -o-transform: scale(1) rotate(1080deg) translate3d(-50%, -50%, 0);
    -moz-transform: scale(1) rotate(1080deg) translate3d(-50%, -50%, 0);
    -ms-transform: scale(1) rotate(1080deg) translate3d(-50%, -50%, 0);
    -webkit-transform: scale(1) rotate(1080deg) translate3d(-50%, -50%, 0);
  }
}